import axios from 'axios';
import apiConfig from "@/config/apiConfig";
import authHeader from "@/services/auth-header";
const API_URL = apiConfig.getApiUrl();

class GlobalService {
    getCountry(countryId) {
        return axios
            .get(API_URL + '/V1/directory/countries/'+countryId, {headers: authHeader()})
            .then(response => {
                console.log(response);
                return response.data;
            });
    }

}
export default new GlobalService();