<template>
  <div id="home-container">
    <div id="home">
      <div class="inner-home-container">
        <div class="home-headline">
          <h1>
            CEMO<br>
            {{$t('Pricefinder')}}
          </h1>
        </div>
        <div class="home-text">
          {{$t('home.home-text')}}
        </div>
        <div class="home-search-type">
          <div class="home-search-type-input-container">
            <b-form-group
                v-slot="{ ariaDescribedby }"
            >
              <b-form-radio-group
                  id="btn-radios-2"
                  v-model="search_type"
                  :options="search_type_option"
                  :aria-describedby="ariaDescribedby"
                  button-variant="outline-light"
                  size="lg"
                  name="radio-btn-outline"
                  buttons
              ></b-form-radio-group>
            </b-form-group>

          </div>
        </div>
        <div class="home-search-input">
          <input type="text" @keyup="isNumber($event)" v-on:keyup.enter="startSearch" :placeholder="getPlaceHolder()" v-model="this.sku" />
        </div>
        <div class="input-invalid-text" v-if="input_show_invalid_text">
          {{$t('home.invalid_input')}}
        </div>
        <div class="home-search-button">
          <div class="v-button v-secondary">
            <a class="start-search-button"   @click="startSearch" href="#start-search">
              {{$t('home.start-search')}}
            </a>
          </div>
        </div>
        <div class="home-glass">
          <add-to-home-screen v-if="showAddToHomeBanner()"></add-to-home-screen>
          <img src="@/assets/img/Lupe.svg" alt="lupe" />
        </div>
      </div>
    </div>
    <CemoContact></CemoContact>
  </div>

</template>

<script>
import AddToHomeScreen from "@/components/partials/AddToHomeScreen";
import DeviceService from "@/services/device.service";
import CemoContact from "@/components/partials/CemoContact";
export default {
  name: "Home",
  components: {CemoContact, AddToHomeScreen},
  data() {
    return {
      sku: '',
      search_type: 'sku',
      search_type_option: [{ text: 'SKU', value: 'sku' },{ text: 'EAN', value: 'ean' }],
      input_valid: false,
      input_class: "",
      input_show_invalid_text:false
    }
  },
  created() {
    this.validateInput(true);
  },
  methods: {
    isNumber: function(evt) {
      console.log(evt);
      this.validateInput();
      // evt = (evt) ? evt : window.event;
      // var charCode = (evt.which) ? evt.which : evt.keyCode;
      // if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
      //   evt.preventDefault();
      // } else {
      //   console.log(charCode);
      //   this.validateInput();
      //   return true;
      // }
      return true;
    },
    validateInput($created = false) {
      if($created) {
        if(this.sku.toString().length > 0) {
          this.input_valid = false;
          this.input_class = "v-invalid";
          this.input_show_invalid_text = true;
        }
      } else {
        if(this.sku.toString().length >= 4) {
          this.input_valid = true;
          this.input_class = "v-valid";
          this.input_show_invalid_text = false;
        } else {
          this.input_valid = false;
          this.input_class = "v-invalid";
          this.input_show_invalid_text = true;
        }
      }
    },
    startSearch(e) {
      e.preventDefault();
      if(this.input_valid) {
        console.log("start search")
        if(this.isNumeric(this.sku)) {
          // this.$router.push('/product-detail/?sku=' + this.sku)
          if(this.search_type === 'sku'){
            this.$router.push({name:'ProductDetail', params:{sku:this.sku, type:this.search_type}})
          } else {
            this.$router.push({name:'SearchResults', params:{sku:this.sku, type:this.search_type}})
          }
        } else {
          // this.$router.push('/search-results/?sku=' + this.sku)
          this.$router.push({name:'SearchResults', params:{sku:this.sku, type:this.search_type}})
        }

      }  else {
        console.log("dont start search")
      }
    },
    showAddToHomeBanner(){
      return DeviceService.isMobileAndPWA();
    },
    isNumeric(str) {
      if (typeof str != "string") return false // we only process strings!
      return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
          !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    },
    getPlaceHolder(){
      if(this.search_type === 'sku') {
        return this.$t('home.type-sku');
      } else if(this.search_type === 'ean') {
        return this.$t('home.type-ean')
      }
    }
  }
}
</script>