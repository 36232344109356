import CartService from "@/services/cart.service";
const cartobj = JSON.parse(localStorage.getItem('cart'));
const initialState = {cartobj};
import store from "@/store/index";
export const cart = {
    namespaced: true,
    state: initialState,
    actions: {
        getData({ commit }) {
            return CartService.getCart().then(
                cart => {
                    console.log("customer module get data")
                    commit('setCartData', cart);
                    return Promise.resolve(cart);
                },
                error => {
                    commit('cartDataFailure');
                    return Promise.reject(error);
                }
            );
        }
    },
    mutations: {
        setCartData(state, cart) {
            console.log("set cart data");
            state.cart = cart;
        },
        cartDataFailure() {
            CartService.createCart().then(()=>{
                store.dispatch("cart/getData");
            });
        },
    }
};