<template>
  <div class="view-cart">
    <div class="view-container">
      <div class="cart-container" v-if="this.cart.items.length > 0">
        <single-cart-item v-for="item in cart.items" :key="item.item_id" :item="item" />
      </div>
      <div class="empty-cart-container" v-else>
        {{ $t("cart.emptyCart") }}
      </div>
      <div class="cart-totals-container" v-if="this.cart.items.length > 0">
        <CartTotal />
      </div>
      <div class="v-button v-primary v-centered" v-if="this.cart.items.length > 0">
        <router-link to="/checkout">
          {{$t('cart.checkout_button')}}
        </router-link>
      </div>
      <div class="v-button v-primary v-centered" v-else>
        <router-link to="/">
          {{$t('backToHome')}}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import SingleCartItem from "@/components/partials/cart/SingleCartItem";
import CartTotal from "@/components/partials/cart/CartTotal";
import CartService from "@/services/cart.service";
export default {
  name: "Cart",
  components: {CartTotal, SingleCartItem},
  data() {
    return{
      cart: this.$store.state.cart.cart,
      cartTotals: false
    }
  },
  created() {
    this.cart = this.$store.state.cart.cart;
    if(!this.cart) {
      CartService.getCart().then(
          cart => {
            this.cart = cart;
          }
      )
    }
  },
  mounted() {
    this.emitter.on('changedCart', () => {
      CartService.getCart().then(
          cart => {
            this.cart = cart;
          }
      )
    })
  }
}

</script>
