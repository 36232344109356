<template>
  <div id="outer-wrapper" :class="'p-'+this.currentRouteName">
    <Header></Header>
    <Loader v-if="loading" />
    <div id="content-wrapper">
      <div id="inner-wrapper">
        <router-view ></router-view>
      </div>
    </div>
    <Footer></Footer>
    <b-modal centered id="cookie-modal" v-model="cookie_modal_show">
      <template #default="{  }">
        <div class="cookie-modal-text">
          <p>{{ $t("cookies_disabled_text") }}</p>
        </div>

      </template>
      <template #footer>
        <div class="empty"></div>
      </template>
    </b-modal>
  </div>
</template>

<script>

import Header from "@/components/layout_partials/Header";
import Footer from "@/components/layout_partials/Footer";
import { BModal} from "bootstrap-vue-3";
import DeviceService from "@/services/device.service";
import Loader from "@/components/layout_partials/Loader";
export default {
  name: 'App',
  components: {
    Loader,
    BModal,
    Footer,
    Header
  },
  data() {
    return {
      cookie_modal_show: false,
      userOS:"",
      userAgent:"",
      osType:"",
    }

  },
  mounted() {
    this.checkDevice();
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    adminLoggedIn() {
      return this.$store.state.auth.status.adminLoggedIn;
    },
    loading(){
      return this.$store.state.loader.loading > 0;
    }
  },
  created() {
    this.checkDevice();
    if(this.loggedIn) {
      this.checkCustomer();
    }
    // if(!this.adminLoggedIn) {
    //   console.log("admin not logged in");
    //   console.log(process.env.VUE_APP_I18N_LOCALE);
    //   let user = apiConfig.getApiUser();
    //   console.log(user);
    //   this.$store.dispatch("auth/adminLogin",user);
    // }
  },
  methods: {
    checkCustomer(){
      console.log("check customer");
      let customerobj = JSON.parse(localStorage.getItem('customer'));
      console.log(customerobj);
      this.$store.dispatch("customer/getData");
    },
    checkCookiesEnabled() {
      if(navigator.cookieEnabled){
        //console.log("cookies enabled");
        this.cookie_modal_show = false;
      }else{
        //console.log("cookies disabled");
        this.cookie_modal_show = true;
      }
    },
    handleLogin(user) {
      this.loading = true;
      this.$store.dispatch("auth/login", user).then(
          () => {
            //console.log("login success");
          },
          (error) => {
            this.loading = false;
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
          }
      );
    },
    handleLogout() {
      this.loading = true;
      this.$store.dispatch("auth/logout").then(
          () => {
            //console.log("logout success");
          },
          (error) => {
            this.loading = false;
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
          }
      );
    },
    checkDevice(){
      //console.log('userAgent; ',navigator.userAgent);

      this.userAgent = navigator.userAgent || '';
      this.userOs = navigator.appVersion || '';

      let osType = this.userAgent.toLowerCase();

      if(osType.includes('iphone')) this.osType = 'ios';
      else if(osType.includes('android')) this.osType = 'android';
      else  this.osType = 'unknown';

      //console.log("OS:"+this.osType);

      let displayMode = 'browser';
      const mqStandAlone = '(display-mode: standalone)';
      if (navigator.standalone || window.matchMedia(mqStandAlone).matches) {
        displayMode = 'standalone';
      }

      DeviceService.setOS(this.osType)
      DeviceService.setDisplayMode(displayMode)
    }
  },
}
</script>
