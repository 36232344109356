<template>
  <div class="cart-total">
    <div class="single-cart-total" :class="'total-'+segment.code" v-for="segment in cartTotals.total_segments" :key="segment.code">
      <div class="cart-total-label">
        {{segment.title}}
      </div>
      <div class="cart-total-value">
        {{toCurrency(segment.value)}}
      </div>
    </div>
  </div>
</template>

<script>
import CartService from "@/services/cart.service";
import ProductService from "@/services/product.service";
import CustomerService from "@/services/customer.service";
export default {
  name: "CartTotal",
  data() {
    return {
      cartTotals: false,
      cart: this.$store.state.cart.cart,
      customer: this.$store.state.customer.customerobj,
      shippingAddress:this.$store.state.checkout.checkoutobj.shippingAddress
    }
  },
  created() {
    this.cart = this.$store.state.cart.cart;
    if(!this.shippingAddress) {
      CustomerService.getShippingAddress().then(
          shippingAdress => {
            this.shippingAddress = shippingAdress;
            delete this.shippingAddress.region;
            delete this.shippingAddress.default_billing;
            delete this.shippingAddress.default_shipping;
            this.getCartTotals();
          }
      )
    } else {
      this.getCartTotals();
    }

    if(!this.cart) {
      CartService.getCart().then(
          cart => {
            this.cart = cart;
          }
      )
    }

    console.log("customer in cart totals");
    console.log(this.customer);

  },
  mounted() {
    this.emitter.on('changedCart', () => {
     this.getCartTotals();
    })
  },
  methods: {
    toCurrency(value) {
      return ProductService.toCurrency(value);
    },
    getCartTotals() {
      let addressInformation= {
        address: this.shippingAddress
      };
      CartService.estimateShippingMethods(this.shippingAddress).then(
        shippingMethods => {
          console.log(shippingMethods);
          if(shippingMethods.length > 1){
            console.log("more found")
          } else {
            addressInformation.shipping_carrier_code = shippingMethods[0].carrier_code;
            addressInformation.shipping_method_code = shippingMethods[0].method_code;

          }
          CartService.getCartTotalsInformation(addressInformation).then(
              cartTotals => {
                console.log("cartTotals");
                console.log(cartTotals);
                this.cartTotals = cartTotals;
              }
          )
        }
      );

    }
  }
}
</script>

<style scoped>

</style>