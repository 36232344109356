<template>
  <div class="view-checkout-shipping">
    <div class="view-container">
      <div class="checkout-steps">
        <div class="single-checkout-step shipping-step-step" :class="{active: this.step==='shipping'}" @click="toShippingStep">
          {{$t("checkout.shipping")}}
        </div>
        <div class="single-checkout-step shipping-step-step" v-if="billingActive" :class="{active: this.step==='billing'}" @click="toBillingStep">
          {{$t("checkout.billing")}}
        </div>
      </div>
      <div class="checkout-shipping-step" v-if="this.step==='shipping'">
        <h3>{{$t('checkout.shipping_address')}}</h3>
        <div class="checkout-shipping-addresses">
          <AddressDisplay v-on:chooseAddress="chooseAddress" v-for="address in this.customer.addresses" checkout="true"
                          :active="address.id === this.selectedShippingAddress.id" :address="address"
                          :key="address.id"></AddressDisplay>
        </div>
        <h3>{{$t('checkout.shipping_methods')}}</h3>
        <Form @submit="handleSubmitShipping" :validation-schema="this.shipping_schema" >
        <div class="checkout-shipping-methods">
          <div
              v-for="method in this.shippingMethods"
              :key="method.method_code"
              data-testid="shipping-method"
          >
            <Field
                v-model="selectedShippingMethod"
                :value="method"
                :id="method.method_code"
                type="radio"
                name="shippingMethod"
                @change="setSelectedShippingMethod()"
            />
            <label :for="method.method_code">
              <span class="label__text">
                {{ method.carrier_title }} - {{ method.method_title }}
              </span>
              <span class="label__price">
            {{ $filters.toCurrency(method.price_incl_tax) }}
          </span>
            </label>
          </div>
          <ErrorMessage name="shippingMethod" class="error-feedback" />
        </div>
          <div class="v-button v-primary v-centered">
            <button href="#billing" type="submit">
              {{$t('checkout.continue_billing')}}
            </button>
          </div>
        </Form>

      </div>
      <div class="checkout-billing-step" v-if="this.step==='billing'">

        <div class="checkout-billing-address">
          <AddressDisplay :address="this.billingAddress" :active="true"></AddressDisplay>
        </div>
        <Form @submit="handleSubmit" :validation-schema="this.billing_schema" >
          <div class="checkout-payment-methods"
              v-for="method in paymentMethods"
              :key="method.id"
          >
            <Field
                v-model="selectedPaymentMethod"
                :value="method"
                :id="method.code"
                type="radio"
                name="paymentMethod"
                @change="setSelectedPaymentMethod"
            />
            <label :for="method.code">
              {{ method.title }}
            </label>

          </div>
          <ErrorMessage name="paymentMethod" class="error-feedback" />


          <div class="checkout-agreement-container">
            <div class="single-agreement" v-for="agreement in agreements" :key="agreement.agreement_id">
              <Field
                :value="agreement.agreement_id"
                type="checkbox"
                :id="'agreement_'+agreement.agreement_id"
                :name="'agreement_'+agreement.agreement_id"
              />
              <label :for="'agreement_'+agreement.agreement_id">
                <a href="#" @click.prevent="openAgreementPopup(agreement.content)">
                  {{agreement.checkbox_text}}
                </a>
              </label>

              <ErrorMessage :name="'agreement_'+agreement.agreement_id" class="error-feedback" />
            </div>
            <div class="agreement-popup" v-if="show_agreement_popup">
              <div class="agreement-popup-content" v-html="agreement_content"></div>
              <a href="#" class="close-agreement-icon" @click.prevent="this.show_agreement_popup=false">X</a>
            </div>
          </div>
          <div class="form-group fg-button v-centered">
            <button class="btn btn-primary btn-block" :disabled="loading" type="submit">
            <span
                v-show="loading"
                class="spinner-border spinner-border-sm"
            ></span>
              <span>Kostenpflichtig bestellen</span>
            </button>
          </div>
        </Form>
      </div>

      <div class="cart-totals-container">
        <CartTotal />
      </div>
    </div>
  </div>
</template>

<script>

import AddressDisplay from "@/components/partials/account/AddressDisplay";
import CartService from "@/services/cart.service";
import CustomerService from "@/services/customer.service";
import CartTotal from "@/components/partials/cart/CartTotal";
import {Field, Form, ErrorMessage} from "vee-validate";
import * as yup from "yup";
import globalService from "@/services/global.service";
import {useToast} from "vue-toastification";

export default {
  name: "Checkout",
  components: {CartTotal, AddressDisplay,   Form, ErrorMessage,
    Field,},
  setup() {
    const toast = useToast();
    return { toast }
  },
  data() {
    const shipping_schema = yup.object().shape({
      shippingMethod: yup.object().required(this.$i18n.t("checkout.shippingRequired")).nullable(),
    });
    const billing_schema = yup.object().shape({
      paymentMethod: yup.object().required(this.$i18n.t("checkout.paymentRequired")),
      agreement_1: yup.string().required(this.$i18n.t("checkout.agreementRequired")),
      agreement_2: yup.string().required(this.$i18n.t("checkout.agreementRequired")),
      agreement_3: yup.string().required(this.$i18n.t("checkout.agreementRequired")),
    });
    return {
      step: "shipping",
      customer: JSON.parse(localStorage.getItem('customer')),
      cart: JSON.parse(localStorage.getItem('cart')),
      shippingAddressId: 0,
      shippingMethods:{},
      paymentMethods:{},
      selectedShippingMethod: null,
      selectedPaymentMethod: null,
      billingAddress:null,
      billingActive: false,
      billing_schema,
      shipping_schema,
      agreement_content: "",
      show_agreement_popup:false
    }
  },
  computed: {
    selectedShippingAddress() {
      return this.$store.state.checkout.checkoutobj.shippingAddress;
    },
    selectedBillingAddress() {
      return this.$store.state.checkout.checkoutobj.billingAddress;
    },
    currentShippingMethod() {
      return this.$store.state.checkout.checkoutobj.shippingMethod;
    },
    currentPaymentMethod() {
      return this.$store.state.checkout.checkoutobj.paymentMethod;
    },
    agreements() {
      return this.$store.state.checkout.checkoutobj.agreements;
    }
  },
  created() {
    this.billingAddress = this.cart.billing_address;
    if(!this.addressValid(this.billingAddress)) {
      this.getStandardBillingAddress();
    }
    this.getPaymentMethods();
    this.selectedShippingMethod = this.currentShippingMethod;
    this.selectedPaymentMethod = this.currentPaymentMethod;
    if(!this.selectedShippingAddress) {
      console.log("shipping address is NOT selected");
      CustomerService.getShippingAddress().then(
          address => {
            this.shippingAddressId = address.id;
            delete address.region;
            delete address.region_id;
            delete address.default_billing;
            delete address.default_shipping;
            this.$store.dispatch("checkout/setShippingAddress", address.id);
            this.getShippingMethods(address);
          }
      )
    } else {
      console.log("shipping address is selected");
      console.log(this.selectedShippingAddress);
      this.shippingAddressId = this.selectedShippingAddress.id;
      this.getShippingMethods(this.selectedShippingAddress)
    }
    this.getShippingAddressId();

  },
  methods: {
    openAgreementPopup(content) {
      this.agreement_content = content;
      this.show_agreement_popup = true;

    },
    handleSubmit(){
      console.log("submit");
      let billingAddress =  JSON.parse(JSON.stringify(this.billingAddress));
      let paymentMethod = {
        method: this.selectedPaymentMethod.code,
        extension_attributes: {agreement_ids: ["1","2","3"]}
      }

      delete billingAddress.default_billing;
      delete billingAddress.default_shipping;
      delete billingAddress.customer_id;
      delete billingAddress.id;

      //console.log(addressInformation);
      globalService.getCountry(billingAddress.country_id).then(
          country => {
            //console.log(country);
            let region = country.available_regions[0];
            billingAddress.region_id = region.id;
            billingAddress.region = region.name;
            billingAddress.region_code = region.code;
          }
      ).then(
          () => {
            console.log(paymentMethod);
            CartService.setPaymentInformation(billingAddress,paymentMethod).then(
                data => {
                  this.toast.success(this.$i18n.t("checkout.checkoutSuccess"));
                  return data;
                },
                error => {
                  this.toast.error(error.message);
                }
            ).then(
                (data) =>{
                this.emitter.emit('changedCart', {'changedCart': true})
                  this.$router.push({name:"orderSuccess",params:{order:data}})
            });
          }
      )

    },
    handleSubmitShipping() {
      console.log("handle submit shipping");
      this.billingActive = true;
      this.toBillingStep();
    },
    getStandardBillingAddress() {
      CustomerService.getBillingAddress().then(
          billingAddress => {
            this.billingAddress = billingAddress;
          }
      )
    },
    addressValid(address) {
      return address.firstname || address.lastname && address.street.length;
    },
    setSelectedShippingMethod () {
      this.$store.dispatch(
          'checkout/setShippingMethod',
          this.selectedShippingMethod
      )
    },
    setSelectedPaymentMethod () {
      this.$store.dispatch(
          'checkout/setPaymentMethod',
          this.selectedPaymentMethod
      ).then(
          () => {
            CartService.getSelectedPaymentMethod().then(
                paymentMethod => {
                  console.log(paymentMethod);
                }
            )
          }
      );
    },
    getShippingMethods(address) {
      CartService.estimateShippingMethods(address).then(
          shippingMethods => {
            ////console.log("shipping Methods");
            //console.log(shippingMethods);
            this.shippingMethods = shippingMethods;
            // let addressInformation = {
            //   shipping_address: address,
            //   shipping_carrier_code: shippingMethods[0].carrier_code,
            //   shipping_method_code: shippingMethods[0].method_code
            // };

          }
      )
    },
    chooseAddress(address) {
      //console.log("choose Address Shipping");
      //console.log(address);
      delete address.region;
      delete address.region_id;
      delete address.default_billing;
      delete address.default_shipping;
      this.getShippingMethods(address);
      this.$store.dispatch(
          'checkout/setShippingAddress',
          address.id
      );
      this.shippingAddressId = address.id;
    },
    getShippingAddressId() {
      if (CartService.isShippingSameAsBilling()) {
        console.log("shipping is same as billing");
        CustomerService.getBillingAddress().then(
            billingAddress => {
              //console.log("BILLING ADDRESS");
              //console.log(billingAddress);
              this.shippingAddressId = billingAddress.id
            }
        )
      } else {
        console.log("shipping is NOT same as billing");
        console.log(CartService.getShippingAddressFromCart());
        this.shippingAddressId = CartService.getShippingAddressFromCart().id;
      }
    },
    toBillingStep() {
      this.step = "billing";
      //console.log("shipping Method");
      //console.log(this.selectedShippingMethod);
      let shipping_address = JSON.parse(JSON.stringify(this.selectedShippingAddress));
      let billing_address =  JSON.parse(JSON.stringify(this.billingAddress));
      let addressInformation = {
        shipping_address: shipping_address,
        billing_address: billing_address,
        shipping_carrier_code: this.selectedShippingMethod.carrier_code,
        shipping_method_code: this.selectedShippingMethod.method_code
      }
      delete addressInformation.shipping_address.default_billing;
      delete addressInformation.shipping_address.default_shipping;
      delete addressInformation.shipping_address.id;


      delete addressInformation.billing_address.default_billing;
      delete addressInformation.billing_address.default_shipping;
      delete addressInformation.billing_address.id;

      //console.log(addressInformation);
      globalService.getCountry(shipping_address.country_id).then(
          country => {
            //console.log(country);
            let region = country.available_regions[0];
            addressInformation.shipping_address.region_id = region.id;
            addressInformation.shipping_address.region = region.name;
            addressInformation.shipping_address.region_code = region.code;
          }
      ).then(
          () => {
            globalService.getCountry(billing_address.country_id).then(
                country => {
                  //console.log(country);
                  let region = country.available_regions[0];
                  addressInformation.billing_address.region_id = region.id;
                  addressInformation.billing_address.region = region.name;
                  addressInformation.billing_address.region_code = region.code;
                  //console.log("region");
                  //console.log(region);
                  //console.log(addressInformation);
                }
                ).then(
                () => {
                  //console.log("addressInformation");
                  //console.log(addressInformation);
                  CartService.setShippingInformation(addressInformation);
                }
            )
          }
      )

    },
    toShippingStep() {
      this.step = "shipping";
    },
    getPaymentMethods() {
      CartService.getPaymentMethods().then(
          paymentMethods => {
            this.paymentMethods = paymentMethods;
          }
      )
    }
  }
}
</script>