<template>
  <div id="search-results">
    <ResultHeader :sku="this.sku" :type="this.type" />
    <div class="products-list">
      <div class="no-results-container" v-if="!products">
        {{$t('product.no_results')}}
      </div>
      <single-product-list v-for="product in products" v-bind:key="product.id" :local_product="product" :search_type="type" :search_string="sku" />
    </div>
    <CemoContact></CemoContact>
  </div>
</template>

<script>
import ResultHeader from "@/components/partials/ResultHeader";
import ProductService from "@/services/product.service";
import SingleProductList from "@/components/partials/products/SingleProductList";
import CemoContact from "@/components/partials/CemoContact";

export default {
  name: "SearchResults",
  components: {CemoContact, SingleProductList, ResultHeader},
  props: ["sku","type"],
  data() {
    return {
      products: true,
      isLoading: true
    }
  },
  methods:{
    findProducts(){
      ProductService.findProducts(this.$t('config.store_code'),this.sku,this.$t('config.store_id'),this.type)
          .then(products => {
            console.log("products");
            console.log(products);
            if(products.data.items.length < 1) {
              this.products = false;
            } else {
              this.products = products.data.items;
            }
            this.isLoading = false;
          });
    }
  },
  created() {
   this.findProducts();

  },
  watch: {
    // whenever question changes, this function will run
    sku: {
      // This will let Vue know to look inside the array
      deep: true,
      // We have to move our method to a handler field
      handler() {
        this.findProducts();
      }
    }
  },
}
</script>
