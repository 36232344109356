import axios from 'axios';
import apiConfig from "@/config/apiConfig";
import authHeader from "@/services/auth-header";
import authHeaderAdmin from "@/services/auth-header-admin";
const API_URL = apiConfig.getApiUrl();
class CartService {
    getCart() {
        return axios
            .get(API_URL + '/V1/carts/mine', {headers: authHeader()})
            .then(response => {
                console.log(response);
                if (response.data) {
                    console.log("cart data");
                    console.log(response.data);
                    localStorage.setItem('cart', JSON.stringify(response.data));
                }
                return response.data;
            }
            );
    }
    getCartItems() {
        return axios
            .get(API_URL + '/V1/carts/mine/items', {headers: authHeader()})
            .then(response => {
                console.log(response);
                return response.data;
            });
    }
    getCartTotals() {
        return axios
            .get(API_URL + '/V1/carts/mine/totals', {headers: authHeader()})
            .then(response => {
                console.log(response);
                return response.data;
            });
    }
    getCartTotalsInformation(addressInformation) {
        return axios
            .post(API_URL + '/V1/carts/mine/totals-information',{addressInformation}, {headers: authHeader()})
            .then(response => {
                console.log(response);
                return response.data;
            });
    }
    createCart() {
        return axios
            .post(API_URL + '/V1/carts/mine',{}, {headers: authHeader()})
            .then(response => {
                console.log(response);
                return response.data;
            });
    }
    addItemToCart(cartItem) {
        return axios
            .post(API_URL + '/V1/carts/mine/items',{cartItem}, {headers: authHeader()})
            .then(response => {
                console.log(response);
                return response.data;
            });
    }
    updateCartItemQty(cartItem) {
        return axios
            .post(API_URL + '/V1/carts/mine/items',{cartItem}, {headers: authHeader()})
            .then(response => {
                console.log(response);
                return response.data;
            });
    }
    deleteCartItem(itemId) {
        return axios
            .delete(API_URL + '/V1/carts/mine/items/'+itemId, {headers: authHeader()})
            .then(response => {
                console.log(response);
                return response.data;
            });
    }
    estimateShippingMethods(address){
        return axios
            .post(API_URL + '/V1/carts/mine/estimate-shipping-methods',{address}, {headers: authHeader()})
            .then(response => {
                console.log("estimate shipping methods");
                console.log(response);
                return response.data;
            });
    }
    setShippingInformation(addressInformation){
        return axios
            .post(API_URL + '/V1/carts/mine/shipping-information',{addressInformation}, {headers: authHeader()})
            .then(response => {
                console.log("shipping-information");
                console.log(response.data);
                return response.data;
            });
    }
    setPaymentInformation(billingAddress,paymentMethod){
        return axios
            .post(API_URL + '/V1/carts/mine/payment-information',{billingAddress: billingAddress, paymentMethod: paymentMethod}, {headers: authHeader()})
            .then(response => {
                console.log("payment-information");
                console.log(response.data);
                return response.data;
            });
    }
    isShippingSameAsBilling() {
        return this.getShippingAddressFromCart().same_as_billing;
    }

    getBillingAddressFromCart(){
        return axios
            .get(API_URL + '/V1/carts/mine/billing-address', {headers: authHeader()})
            .then(response => {
                console.log("cart billing address");
                console.log(response);
                return response.data;
            });

    }
    getPaymentMethods(){
        return axios
            .get(API_URL + '/V1/carts/mine/payment-methods', {headers: authHeader()})
            .then(response => {
                console.log("Payment Methods");
                console.log(response);
                return response.data.filter(paymentMethod => paymentMethod.code !== 'paypal_express');
            });

    }
    setPaymentMethod(method) {
        return axios
            .put(API_URL + '/V1/carts/mine/selected-payment-method', {method},{headers: authHeader()})
            .then(response => {
                console.log("Set Selected Payment Method");
                console.log(response);
                return response.data;
            });
    }
    getSelectedPaymentMethod() {
        return axios
            .get(API_URL + '/V1/carts/mine/selected-payment-method', {headers: authHeader()})
            .then(response => {
                console.log("Get Selected Payment Methods");
                console.log(response);
                return response.data;
            });
    }
    getShippingAddressFromCart() {
        let cart = JSON.parse(localStorage.getItem('cart'));
        return cart.extension_attributes['shipping_assignments'][0].shipping.address;
    }
    getAgreements() {
        return axios
            .get(API_URL + '/V1/carts/licence', {headers: authHeaderAdmin()})
            .then(response => {
                console.log("Agreements");
                console.log(response);
                return response.data;
            },
                error => {
                    console.log(error);
                }
            );


    }
}
export default new CartService();