<template>
  <div class="single-product-list" v-if="this.product && this.product.type_id == 'simple'">
    <div class="spl-inner">
      <div class="spl-img">
        <router-link
            :to="{ name: 'ProductDetail', params: { sku: this.product.sku} }"
        >
          <img :src="this.getImageUrl()" alt="product_image" @error="getPlaceHolderUrl"  />
        </router-link>
      </div>
      <div class="spl-text-container">
        <div class="spl-text-inner">
          <div class="spl-name">
            {{product.name}}
          </div>
          <div class="spl-sku">
            {{$t("search-result.SKU")}}: {{product.sku}}
          </div>
          <div class="spl-ean">
            <div v-if="search_type == 'ean'">EAN: <span v-html="highlightSearchText(search_string,attributes['ean'])" ></span></div>
            <div v-else >EAN: {{attributes.ean}}</div>
          </div>
        </div>
        <div class="spl-price">
          <div class="spl-price-text">
            {{price}}
          </div>
          <div class="v-button v-primary">
            <router-link
                :to="{ name: 'ProductDetail', params: { sku: this.product.sku} }"
            >
              {{$t('search-result.more')}}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import apiConfig from "@/config/apiConfig";
import ProductService from "@/services/product.service";
export default {
  name: "SingleProductList",
  props: ["local_product","search_type","search_string"],
  data() {
    return {
      media_url: apiConfig.getMediaUrl(),
      short_description: "",
      price: ProductService.toCurrency(0),
      attributes: [],
      product: false
    }
  },
  created() {
    if(this.product) {
      // eslint-disable-next-line no-unused-vars
      for (let [key,value] of Object.entries(this.product.custom_attributes)) {
        this.attributes[value.attribute_code] = value.value;
      }
      if(this.attributes['app_price']) {
        this.price = ProductService.toCurrency(this.attributes['app_price']);
      } else {
        this.price = ProductService.toCurrency(this.product.price);
      }
    }
  },
  mounted() {
    ProductService.getProductById(this.$t('config.store_code'),this.local_product.id)
        .then(product => {
          this.product = product.data.items[0];
          this.updateAppAttributes();
        });
  },
  methods: {
    getPlaceHolderUrl(event){
      event.target.src = apiConfig.getPlaceholderUrl();
    },
    getImageUrl(){
      console.log("get image url");
      let img_url = this.media_url;
      let found = false;
      // eslint-disable-next-line no-unused-vars
      for (let [key,value] of Object.entries(this.product.custom_attributes)) {
        if(value.attribute_code==='image') {
          img_url = img_url + "" + value.value
          found = true
        }
        if(value.attribute_code==='short_description') {
          this.short_description = value.value
        }
      }
      if(found) {
        return img_url;
      } else {
        return apiConfig.getPlaceholderUrl();
      }

    },
    updateAppAttributes() {
      if (this.product) {
        // eslint-disable-next-line no-unused-vars
        for (let [key, value] of Object.entries(this.product.custom_attributes)) {
          this.attributes[value.attribute_code] = value.value;
        }
        if (this.attributes['app_price']) {
          console.log("app_price");
          console.log(this.attributes['app_price']);
          if(this.attributes['app_price'] == 0) {
            this.price = this.$t('product.on_inquiry');
          } else {
            this.price = ProductService.toCurrency(this.attributes['app_price']);
          }
        } else {
          this.price = ProductService.toCurrency(this.product.price);
        }
        if (this.attributes["app_price_available_till"]) {
          this.price_validity = ProductService.formatDate(this.attributes["app_price_available_till"]);
        }
        if (this.attributes['app_delivery_price']) {
          if(this.attributes['app_delivery_price'] == 0) {
            this.shipping_cost = this.$t('product.on_inquiry');
            this.is_real_price = false;
          } else {
            this.shipping_cost = ProductService.toCurrency(this.attributes['app_delivery_price']);
          }
        }
        if (this.attributes['app_catalog_page']) {
          this.catalog_page = this.attributes['app_catalog_page'];
        }
        if (this.attributes['app_delivery_info']) {
          this.delivery_time = this.attributes['app_delivery_info'];
        } else {
          this.delivery_time = this.attributes['delivery_time'];
        }
      }
    },
    highlightSearchText($search_string,$value){
      return $value.replace($search_string,'<span class="v-highlit-search">'+$search_string+'</span>');
    }
  }
}
</script>
